import { books } from "./research/books";
import { bookChapters } from "./research/book-chapters";
import { tutorials } from "./research/tutorials";
import { conferencePapers } from "./research/conference-papers";
import { journalPapers } from "./research/journal-papers";
import { demosAndWorkshopPapers } from "./research/demos-and-workshop-papers";
import Book from "./Book";
import Tutorial from "./Tutorial";
import Paper from "./Paper";

const palette = ["red", "green", "blue", "yellow", "purple", "indigo", "pink"];

export default function Research() {
  return (
    <div className="py-2">
      <main>
        {/* {Publications} */}
        <div className="max-w-7xl mx-auto pt-6 pb-2 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 flex flex-wrap">
            <span className="mr-3">Publications</span>
            <a
              href="https://scholar.google.com/citations?user=xkx5nVQAAAAJ&hl=en"
              className="mr-3 text-base flex flex-row items-center text-blue-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>{" "}
              Google Scholar
            </a>
            <a
              href="http://dblp.uni-trier.de/pers/hd/l/Li:Liangyue"
              className="text-base flex flex-row items-center text-blue-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                />
              </svg>
              DBLP
            </a>
          </h1>
        </div>
        <div className="max-w-7xl mx-auto py-0 px-4 sm:px-6 lg:px-8 flex flex-col space-x-2 md:flex-row sm:flex-col flex-wrap">
          <div className="font-medium">Jump To:</div>
          <a
            className="font-medium border-b-2 border-red-300 max-w-min whitespace-nowrap hover:text-red-300 transiton duration-200"
            href="#tutorials"
          >
            Tutorials
          </a>
          <a
            className="font-medium	border-b-2 border-yellow-300 max-w-min whitespace-nowrap hover:text-yellow-300 transiton duration-200"
            href="#books"
          >
            Books
          </a>
          <a
            className="font-medium	border-b-2 border-green-300 max-w-min whitespace-nowrap hover:text-green-300 transiton duration-200"
            href="#book-chapters"
          >
            Book Chapters
          </a>
          <a
            className="font-medium	border-b-2 border-blue-300 max-w-min whitespace-nowrap hover:text-blue-300 transiton duration-200"
            href="#journal"
          >
            Journal Papers
          </a>
          <a
            className="font-medium	border-b-2 border-indigo-300 max-w-min whitespace-nowrap hover:text-indigo-300 transiton duration-200"
            href="#conference"
          >
            Conference Papers
          </a>
          <a
            className="font-medium	border-b-2 border-purple-300 max-w-min whitespace-nowrap hover:text-purple-300 transiton duration-200"
            href="#demos"
          >
            Demos and Workshop Papers
          </a>
        </div>
        <div className="max-w-7xl mx-auto py-0 sm:px-6 lg:px-8 text-justify">
          <div className="px-4 py-2 sm:px-0">
            {/* Tutorials */}
            <h2 id="tutorials" className="font-semibold text-red-600 text-lg pt-2">
              Tutorials
            </h2>
            <ol className="list-decimal px-6">
              {tutorials.map((tutorial, index) => (
                <li key={index} className="text-gray-600 font-medium">
                  <Tutorial tutorial={tutorial} palette={palette} />
                </li>
              ))}
            </ol>

            {/* Books */}
            <h2 id="books" className="font-semibold text-yellow-600 mt-4 text-lg pt-2">
              Books
            </h2>
            <ol className="list-decimal px-6">
              {books.map((book, index) => (
                <li key={index} className="text-gray-600 font-medium">
                  <Book book={book} palette={palette} />
                </li>
              ))}
            </ol>

            {/* Book Chapters */}
            <h2 id="book-chapters" className="font-semibold text-green-600 mt-4 text-lg pt-2">
              Book Chapters
            </h2>
            <ol className="list-decimal px-6">
              {bookChapters.map((bookChapter, index) => (
                <li key={index} className="text-gray-600 font-medium">
                  <Book book={bookChapter} palette={palette} />
                </li>
              ))}
            </ol>

            {/* Journal Papers */}
            <h2 id="journal" className="font-semibold text-blue-600 mt-4 text-lg pt-2">
              Journal Papers
            </h2>
            <ol className="list-decimal px-6">
              {journalPapers.map((paper, index) => (
                <li key={index} className="text-gray-600 font-medium">
                  <Paper paper={paper} palette={palette} />
                </li>
              ))}
            </ol>

            {/* Conference Papers */}
            <h2 id="conference" className="font-semibold text-indigo-600 mt-4 text-lg pt-2">
              Conference Papers
            </h2>
            <ol className="list-decimal px-6">
              {conferencePapers.map((paper, index) => (
                <li key={index} className="text-gray-600 font-medium">
                  <Paper paper={paper} palette={palette} />
                </li>
              ))}
            </ol>

            {/* Demo and Workshop Papers */}
            <h2 id="demos" className="font-semibold text-purple-600 mt-4 text-lg pt-2">
              Demos and Workshop Papers
            </h2>
            <ol className="list-decimal px-6">
              {demosAndWorkshopPapers.map((paper, index) => (
                <li key={index} className="text-gray-600 font-medium">
                  <Paper paper={paper} palette={palette} />
                </li>
              ))}
            </ol>
          </div>
        </div>

        {/* Projects */}
        <div className="max-w-7xl mx-auto pt-8 pb-0 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Projects</h1>
        </div>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-2 sm:px-0">
            <ul className="list-disc px-6">
              <li>
                <a href="http://team-net-work.org/" className="text-blue-700 font-medium">
                  Teamwork in Big Networks
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}
