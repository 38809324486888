import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Homepage from "./Homepage";
import Research from "./Research";
import Services from "./Services.jsx";
import Experiences from "./Experiences";
import Misc from "./Misc";
import Default from "./Default";

const navigation = [
  ["homepage", "blue"],
  ["research", "indigo"],
  ["services", "purple"],
  ["experiences", "pink"],
  ["misc", "red"],
];

export default function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <Router>
        <Disclosure as="nav">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center justify-between">
                    <div className="flex-shrink-0">
                      <span className="text-gray-600 text-xl font-black">Liangyue Li, Ph.D.</span>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item, itemIdx) => (
                          <Link
                            to={item[0]}
                            key={item[0]}
                            data-id={itemIdx}
                            className={`text-gray-700 bg-${item[1]}-100 hover:bg-${item[1]}-300 hover:text-white transition-all duration-300	px-3 py-2 rounded-md text-sm font-semibold capitalize`}
                          >
                            {item[0]}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-200 inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-white hover:bg-gray-500 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item, itemIdx) => (
                    <Link
                      to={item[0]}
                      key={item[0]}
                      data-id={itemIdx}
                      className={`text-gray-700 bg-${item[1]}-100 hover:bg-${item[1]}-300 hover:text-white block transition-all duration-300 px-3 py-2 rounded-md text-base font-semibold capitalize`}
                    >
                      {item[0]}
                    </Link>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="flex-grow">
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/homepage" component={Homepage} />
            <Route exact path="/research" component={Research} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/experiences" component={Experiences} />
            <Route exact path="/misc" component={Misc} />
            <Route component={Default} />
          </Switch>
        </div>
      </Router>

      <footer className="w-full text-center bg-gray-700 text-gray-400 p-5">
        <div>Liangyue Li © 2021-{new Date().getFullYear()}. Designed by <a
                className="hover:bg-blue-50 transition-all duration-200"
                href="https://daweigu.io/"> Dawei Gu.</a></div>
      </footer>
    </div>
  );
}
