export default function Paper({ paper, palette }) {
  const authorsRenderArray = paper.authors.map((author, authorIndex) => (
    <span className={authorIndex === paper.authorIndex ? "underline" : ""}>{author}</span>
  ));
  const  linksRenderArray = Object.entries(paper.links).map((link, linkIndex) => (
    <a
      href={link[1]}
      className={`text-${palette[linkIndex % palette.length]}-600 font-semibold hover:bg-${
        palette[linkIndex % palette.length]
      }-50 transition-all duration-200 capitalize`}
    >
      {link[0]}
    </a>
  ));
  return (
    <div className="py-2">
      {authorsRenderArray.reduce((prev, next) => [prev, ", ", next])}.{" "}
      {<span className="italic">{paper.title}</span>}
      {/[\w]/.test(paper.title.slice(-1)) ? "." : ""} {paper.place}
      {paper.organization ? " (" : ""}
      {<span className="font-bold">{paper.organization}</span>}
      {paper.organization ? ")" : ""}, {paper.year}.{" "}
      {paper.notes ? <span className="italic">({paper.notes})</span> : ""}{" "}
      {linksRenderArray.length > 0 ? "[" : ""}
      {linksRenderArray.length > 0
        ? linksRenderArray.reduce((prev, next) => [prev, "][", next])
        : ""}
      {linksRenderArray.length > 0 ? "]" : ""}
    </div>
  );
}
