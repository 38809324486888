export default function Misc() {
  return (
    <div className="py-2">
      <main>
        <div className="max-w-7xl mx-auto pt-6 pb-2 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            <span className="mr-3">Miscellaneous</span>
          </h1>
        </div>
        <div className="px-4 max-w-7xl mx-auto py-0 sm:px-6 lg:px-8">
          <h2 className="font-semibold text-lg">Invited Talks</h2>
          <div className="px-4 py-2 sm:px-0">
            <ul className="list-disc px-6 text-gray-600 font-medium">
              <li>
                Towards Optimal Teams in Big Networks, Keynote Speech at{" "}
                <a
                  href="https://cn.aminer.org/bignet16"
                  className="text-blue-700 hover:bg-blue-50 transition-all duration-200"
                >
                  CIKM 2016 Workshop on Big Network Analytics (BigNet 2016)
                </a>
              </li>
            </ul>
          </div>

          <h2 className="font-semibold mt-4 text-lg">Awards and Honors</h2>
          <div className="px-4 py-2 sm:px-0">
            <ul className="list-disc px-6 text-gray-600 font-medium">
              <li>SIGKDD Student Travel Award, 2017</li>
              <li>Travel Award for SBP-BRiMS 2017 Doctoral Consortium, 2017</li>
              <li>
                ACM SIGKDD Student Scholarship for ACM's Celebration of 50 Years of the Turing
                Award, 2017
              </li>
              <li>SIGIR Student Travel Award for CIKM, 2016</li>
              <li>SIGKDD Student Travel Award, 2016</li>
              <li>SIGKDD Student Travel Award, 2015</li>
              <li>The Best Student Paper Honorable Mention Award, FG, 2013</li>
              <li>First-class scholarship of Tongji University (Top %5), 2008,2009,2010</li>
              <li>National Scholarship, Ministry of Education, China, 2008</li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}
