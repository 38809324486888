export default function Book({ book, palette }) {
  const authorsRenderArray = book.authors.map((author, authorIndex) => (
    <span className={authorIndex === book.authorIndex ? "underline" : ""}>{author}</span>
  ));
  const linksRenderArray = Object.entries(book.links).map((link, linkIndex) => (
    <a
      href={link[1]}
      className={`text-${palette[linkIndex % palette.length]}-600 font-semibold hover:bg-${
        palette[linkIndex % palette.length]
      }-50 transition-all duration-200 capitalize`}
    >
      {link[0]}
    </a>
  ));
  return (
    <div className="py-2">
      {authorsRenderArray.reduce((prev, next) => [prev, ", ", next])}.{" "}
      {<span className="italic">{book.title}</span>}, {book.press}, {book.year}. (
      {book.identifier.name}: {book.identifier.id}) {linksRenderArray.length > 0 ? "[" : ""}
      {linksRenderArray.length > 0
        ? linksRenderArray.reduce((prev, next) => [prev, "][", next])
        : ""}
      {linksRenderArray.length > 0 ? "]" : ""}
    </div>
  );
}
