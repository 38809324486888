export const conferencePapers = [
  {
    authors: [`Chenglin Li`, `Qianglong Chen`, `Liangyue Li`, `Caiyu Wang`, `Feng Tao`, `Yicheng Li`, `Zulong Chen`, `Yin Zhang`],
    authorIndex: 2,
    title: `Mixed Distillation Helps Smaller Language Models Reason Better`,
    year: 2024,
    place: `The 2024 Conference on Empirical Methods in Natural Language Processing`,
    organization: `EMNLP`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Tianli Zhang`, `Tongya Zheng`, `Zhenbang Xiao`, `Zulong Chen`, `Liangyue Li`, `Zunlei Feng`, `Dongxiang Zhang`, `Mingli Song`],
    authorIndex: 4,
    title: `Language Models-enhanced Semantic Topology Representation Learning for Temporal Knowledge Graph Extrapolation`,
    year: 2024,
    place: `33rd ACM International Conference on Information & Knowledge Management`,
    organization: `CIKM`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Zhenghao Liu`, `Zulong Chen`, `Moufeng Zhang`, `Shaoyang Duan`, `Hong Wen`, `Liangyue Li`, `Nan Li`, `Yu Gu`, `Ge Yu`],
    authorIndex: 5,
    title: `Modeling User Viewing Flow using Large Language Models for Article Recommendation`,
    year: 2024,
    place: `The Web Conference`,
    organization: `WWW`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Shigang Quan`, `Hailong Tan`, `Shui Liu`, `Zhenzhe Zheng`, `Ruihao Zhu`, `Liangyue Li`, `Quan Lu`, `Fan Wu`],
    authorIndex: 5,
    title: `MERIT: A Merchant Incentive Ranking Model for Hotel Search & Ranking`,
    year: 2023,
    place: `32nd ACM International Conference on Information & Knowledge Management`,
    organization: `CIKM`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Senrong Xu`, `Liangyue Li`, `Yuan Yao`, `Zulong Chen`, `Han Wu`, `Quan Lu`, `Hanghang Tong`],
    authorIndex: 1,
    title: `MUSENET: Multi-Scenario Learning for Repeat-Aware Personalized Recommendation`,
    year: 2023,
    place: `16th ACM International Conference on Web Search and Data Mining`,
    organization: `WSDM`,
    links: {},
    notes: `Alibaba Innovative Research`,
  },
  {
    authors: [`Jia Xu`, `Fei Xiong`, `Zulong Chen`, `Mingyuan Tao`, `Liangyue Li`, `Quan Lu`],
    authorIndex: 4,
    title: `G2NET: A General Geography-Aware Representation Network for Hotel Search Ranking`,
    year: 2022,
    place: `28th ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Wanjie Tao`, `Liangyue Li`, `Chen Chen`, `Zulong Chen`, `Hong Wen`],
    authorIndex: 1,
    title: `When Online Meets Offline: Exploring Periodicity for Travel Destination Prediction`,
    year: 2022,
    place: `The 45th International ACM SIGIR Conference on Research and Development in Information Retrieval`,
    organization: `SIGIR`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Wanjie Tao`, `Zhang-Hua Fu`, `Liangyue Li`, `Zulong Chen`, `Hong Wen`, `Yuanyuan Liu`, `Qijie Shen`, `Peilin Chen`],
    authorIndex: 2,
    title: `A Dual Channel Intent Evolution Network for Predicting Period-Aware Travel Intentions at Fliggy`,
    year: 2022,
    place: `31st ACM International Conference on Information & Knowledge Management`,
    organization: `CIKM`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Han Wu`, `Hongzhe Zhang`, `Liangyue Li`, `Zulong Chen`, `Fanwei Zhu`, `Xiao Fang`],
    authorIndex: 2,
    title: `Cheaper is Better: Exploring Price Competitiveness for Online Purchase Prediction`,
    year: 2022,
    place: `38th IEEE International Conference on Data Engineering`,
    organization: `ICDE`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Wanjie Tao`, `Yu Li`, `Liangyue Li`, `Zulong Chen`, `Hong Wen`, `Peilin Chen`,  `Tingting Liang`, `Quan Lu`],
    authorIndex: 2,
    title: `SMINet: State-Aware Multi-Aspect Interests Representation Network for Cold-Start Users Recommendation`,
    year: 2022,
    place: `Thirty-Sixth AAAI Conference on Artificial Intelligence`,
    organization: `AAAI`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Qinghai Zhou`, `Liangyue Li`, `Xintao Wu`, `Nan Cao`, `Lei Ying`, `Hanghang Tong`],
    authorIndex: 1,
    title: `ATTENT: Active Attributed Network Alignment`,
    year: 2021,
    place: `30th The Web Conference`,
    organization: `WWW`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Bijaya Adhikari`, `Liangyue Li`, `Nikhil Rao`, `Karthik Subbian`],
    authorIndex: 1,
    title: `Finding Needles in Heterogeneous Haystacks`,
    year: 2021,
    place: `Annual Conference on Innovative Applications of Artificial Intelligence`,
    organization: `IAAI`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Qinghai Zhou`, `Liangyue Li`, `Hanghang Tong`],
    authorIndex: 1,
    title: `Towards Real Time Team Optimization`,
    year: 2019,
    place: `2019 IEEE International Conference on Big Data`,
    organization: `IEEE Big Data`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Qinghai Zhou`, `Liangyue Li`, `Nan Cao`, `Lei Ying`, `Hanghang Tong`],
    authorIndex: 1,
    title: `Admiring: Adversarial Multi-Network Mining`,
    year: 2019,
    place: `19th IEEE International Conference on Data Mining`,
    organization: `ICDM`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Liangyue Li`, `Hanghang Tong`, `Huan Liu`],
    authorIndex: 0,
    title: `Towards Explainable Networked Prediction`,
    year: 2018,
    place: `The 27th ACM International Conference on Information and Knowledge Management`,
    organization: `CIKM`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiTL_CIKM18.pdf`,
    },
    notes: ``,
  },
  {
    authors: [
      `Liangyue Li`,
      `Hanghang Tong`,
      `Yong Wang`,
      `Conglei Shi`,
      `Nan Cao`,
      `Norbou Buchler`,
    ],
    authorIndex: 0,
    title: `Is the Whole Greater Than the Sum of Its Parts?`,
    year: 2017,
    place: `23rd ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiTWSCB_KDD17.pdf`,
      Slides: `http://turingcat.me/pdfs/PAROLE-KDD17.pdf`,
      YouTube: `https://www.youtube.com/watch?v=0TcVI8PAa8U`,
      Code: `https://github.com/jackiey99/part-whole`,
    },
    notes: `Oral Presentation`,
  },
  {
    authors: [`Liangyue Li`, `How Jing`, `Hanghang Tong`, `Jaewon Yang`, `Qi He`, `Bee-Chung Chen`],
    authorIndex: 0,
    title: `NEMO: Next Career Move Prediction with Contextual Embedding`,
    year: 2017,
    place: `26th International World Wide Web Conference`,
    organization: `WWW`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiJTYHC_WWW17.pdf`,
      Slides: `http://turingcat.me/pdfs/NEMO-WWW17.pdf`,
    },
    notes: `Industry Track`,
  },
  {
    authors: [`Liangyue Li`, `Yuan Yao`, `Jie Tang`, `Wei Fan`, `Hanghang Tong`],
    authorIndex: 0,
    title: `QUINT: On Query-Specific Optimal Networks`,
    year: 2016,
    place: `22nd ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiYTFT_KDD16.pdf`,
      Slides: `http://turingcat.me/pdfs/KDD16_QUINT.pdf`,
      YouTube: `https://www.youtube.com/watch?v=bezkUnBBwIw`,
      Code: `https://github.com/jackiey99/QUINT`,
    },
    notes: `Full Presentation`,
  },
  {
    authors: [`Liangyue Li`, `Hanghang Tong`, `Jie Tang`, `Wei Fan`],
    authorIndex: 0,
    title: `iPath: Forecasting the Pathway to Impact`,
    year: 2016,
    place: `SIAM International Conference on Data Mining`,
    organization: `SDM`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiTTF_SDM16.pdf`,
      Slides: `http://turingcat.me/pdfs/SDM16_iPath.pdf`,
    },
    notes: ``,
  },
  {
    authors: [`Liangyue Li`, `Hanghang Tong`],
    authorIndex: 0,
    title: `The Child is Father of the Man: Foresee the Success at the Early Stage`,
    year: 2015,
    place: `21st ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiT_KDD15.pdf`,
      Slides: `http://turingcat.me/pdfs/KDD15_citationpred.pdf`,
    },
    notes: `Research Track`,
  },
  {
    authors: [
      `Liangyue Li`,
      `Hanghang Tong`,
      `Nan Cao`,
      `Kate Ehrlich`,
      `Yu-Ru Lin`,
      `Norbou Buchler`,
    ],
    authorIndex: 0,
    title: `Replacing the Irreplaceable: Fast Algorithm for Team Member Recommendation`,
    year: 2015,
    place: `International World Wide Web Conference`,
    organization: `WWW`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiTCELB_WWW15.pdf`,
      Slides: `http://turingcat.me/pdfs/WWW15_teamrep.pdf`,
      Code: `http://turingcat.me/teamrep.html`,
    },
    notes: `Research Track, Acceptance Rate: 14.1%`,
  },
  {
    authors: [`Liangyue Li`, `Hanghang Tong`, `Yanghua Xiao`, `Wei Fan`],
    authorIndex: 0,
    title: `Cheetah: Fast Graph Kernel Tracking on Dynamic Graphs.`,
    year: 2015,
    place: `SIAM International Conference on Data Mining`,
    organization: `SDM`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiTXF_SDM15.pdf`,
      Slides: `http://turingcat.me/pdfs/SDM15_cheetah.pdf`,
      Code: `http://turingcat.me/cheetah.html`,
    },
    notes: `Oral`,
  },
  {
    authors: [`Nan Cao`, `Yu-Ru Lin`, `Liangyue Li`, `Hanghang Tong`],
    authorIndex: 2,
    title: `g-Miner: Interactive Visual Group Mining on Multivariate Graphs`,
    year: 2015,
    place: `ACM Conference on Human Factors in Computing Systems`,
    organization: `CHI`,
    links: {
      PDF: `http://turingcat.me/pdfs/CaoLLT_CHI15.pdf`,
      Video: `http://nancao.org/videos/gminer.mp4`,
    },
    notes: ``,
  },
  {
    authors: [`Ming Shao`, `Liangyue Li`, `Yun Fu`],
    authorIndex: 1,
    title: `What Do You Do? Recognize Occupations in a Photo via Social Context`,
    year: 2013,
    place: `International Conference on Computer Vision`,
    organization: `ICCV`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Ming Shao`, `Liangyue Li`, `Yun Fu`],
    authorIndex: 1,
    title: `Predicting Professions through Probabilistic Model under Social Context`,
    year: 2013,
    place: `17th AAAI Conference on Late-Breaking Developments in the Field of Artificial Intelligence`,
    organization: `AAAI`,
    links: {},
    notes: `Late-breaking Track`,
  },
  {
    authors: [`Liangyue Li`, `Sheng Li`, `Yun Fu`],
    authorIndex: 0,
    title: `Discriminative Dictionary Learning with Low-Rank Regularization for Face Recognition`,
    year: 2013,
    place: `10th IEEE International Conference on Automatic Face and Gesture Recognition`,
    organization: `FG`,
    links: {},
    notes: `Best Student Paper Honorable Mention`,
  },
  {
    authors: [`Zhenghong Gu`, `Ming Shao`, `Liangyue Li`, `Yun Fu`],
    authorIndex: 2,
    title: `Discriminative Metric: Schatten Norm vs. Vector Norm`,
    year: 2012,
    place: `21st International Conference on Pattern Recognition`,
    organization: `ICPR`,
    links: {},
    notes: ``,
  },
];
