// only links are optional
export const tutorials = [
  {
    authors: [`Liangyue Li`, `Hanghang Tong`],
    authorIndex: 0,
    title: `Network Science of Teams: Characterization, Prediction, and Optimization`,
    year: 2018,
    conference: `24th ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    links: {
      website: `http://turingcat.me/team-tutorial.html`,
    },
  },
  {
    authors: [`Liangyue Li`, `Hanghang Tong`],
    authorIndex: 0,
    title: `Network Science of Teams: Characterization, Prediction, and Optimization`,
    year: 2018,
    conference: `The 11th ACM International Conference on Web Search and Data Mining`,
    organization: `WSDM`,
    links: {},
  },
];
