export const journalPapers = [
  {
    authors: [`Wenjian Xu`, `Shui Liu`, `Zulong Chen`, `Ying Zhou`, `Liangyue Li`, `Yangyang Wang`, `Quan Lu`, `Yuyang Ji`, `Jian Wan`],
    authorIndex: 4,
    title: `ROI constrained optimal online allocation in sponsored search`,
    year: 2024,
    place: `Scientific reports`,
    organization: ``,
    links: {},
    notes: ``,
  },
  {
    authors: [`Huiyao Chen`, `Yu Zhao`, `Zulong Chen`, `Mengjia Wang`, `Liangyue Li`, `Meishan Zhang`, `Min Zhang`],
    authorIndex: 4,
    title: `Retrieval-style In-Context Learning for Few-shot Hierarchical Text Classification`,
    year: 2024,
    place: `Transactions of the Association for Computational Linguistics`,
    organization: `TACL`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Senrong Xu`, `Liangyue Li`, `Zenan Li`, `Yuan Yao`, `Feng Xu`, `Zulong Chen`, `Quan Lu`, `Hanghang Tong`],
    authorIndex: 1,
    title: `On the Vulnerability of Graph Learning-based Collaborative Filtering`,
    year: 2023,
    place: `ACM Transactions on Information Systems`,
    organization: `TOIS`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Qinghai Zhou`, `Liangyue Li`, `Nan Cao`, `Lei Ying`, `Hanghang Tong`],
    authorIndex: 1,
    title: `Adversarial Attacks on Multi-Network Mining: Problem Definition and Fast Solutions`,
    year: 2021,
    place: `IEEE Transactions on Knowledge and Data Engineering`,
    organization: `TKDE`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Liangyue Li`, `Hanghang Tong`],
    authorIndex: 0,
    title: `Network Science of Teams: Current State and Future Tools`,
    year: 2018,
    place: `IEEE Intelligent Informatics Bulletin, Vol. 19, No. 2`,
    organization: `IIB`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Yong Wang`, `Conglei Shi`, `Liangyue Li`, `Hanghang Tong`, `Huamin Qu`],
    authorIndex: 2,
    title: `Visualizing Research Impact Through Citation Data`,
    year: 2017,
    place: `ACM Transactions on Interactive Intelligent Systems`,
    organization: `TIIS`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Muge Li`, `Liangyue Li`, `Feiping Nie`],
    authorIndex: 1,
    title: `Ranking with Adaptive Neighbors`,
    year: 2017,
    place: `Tsinghua Science and Technology`,
    organization: ``,
    links: {},
    code: ``,
  },
  {
    authors: [
      `Liangyue Li`,
      `Hanghang Tong`,
      `Nan Cao`,
      `Kate Ehrlich`,
      `Yu-Ru Lin`,
      `Norbou Buchler`,
    ],
    authorIndex: 0,
    title: `Enhancing Team Composition in Professional Networks: Problem Definitions and Fast Solutions`,
    year: 2016,
    place: `IEEE Transactions on Knowledge and Data Engineering`,
    organization: `TKDE`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Liangyue Li`, `Sheng Li`, `Yun Fu`],
    authorIndex: 0,
    title: `Learning Low-Rank and Discriminative Dictionary for Image Classification`,
    year: 2014,
    place: `Image and Vision Computing`,
    organization: `IVC`,
    links: {},
    notes: `Invited Paper`,
  },
  {
    authors: [`Weiai Wayne Xu`, `Liangyue Li`, `Michael A. Stefanone`, `Yun Fu`],
    authorIndex: 1,
    title: `Does social media users’ commenting behavior differ by their local community tie? A computer–assisted linguistic analysis approach`,
    year: 2014,
    place: `First Monday`,
    organization: ``,
    links: {},
    notes: ``,
  },
];
