import { services } from "./services/services-fe";

export default function Services() {
  return (
    <div className="py-2">
      <main>
        <div className="max-w-7xl mx-auto pt-6 pb-2 px-4 sm:px-6 lg:px-8 text-justify">
          <h1 className="text-3xl font-bold text-gray-900">
            <span className="mr-3">Services</span>
          </h1>
        </div>
        <div className="max-w-7xl mx-auto py-0 sm:px-6 lg:px-8">
          <div className="px-4 py-2 sm:px-0 text-gray-600 font-medium">
            <ul className="list-disc px-6">
              {services.map((service, index) => (
                <li key={index} className="py-1">
                  {service.position}:{" "}
                  {service.link ? (
                    <a href={service.link} className="text-blue-700">
                      {service.place}
                    </a>
                  ) : (
                    service.place
                  )}{" "}
                  (<span className="font-bold">{service.organization}</span>), {service.year}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}
