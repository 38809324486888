// links are optional
export const bookChapters = [
  {
    authors: [`Sheng Li`, `Liangyue Li`, `Yun Fu`],
    authorIndex: 1,
    title: `Low-Rank Dictionary Learning, Low-Rank and Sparse Modeling for Visual Analysis`,
    year: 2014,
    press: `Springer`,
    links: {},
    identifier: {
      name: `ISBN`,
      id: `978-3-319-12000-3`,
    },
  },
];
