export const demosAndWorkshopPapers = [
  {
    authors: [`Senrong Xu`, `Yuan Yao`, `Liangyue Li`, `Wei Yang`, `Feng Xu`, `Hanghang Tong`],
    authorIndex: 2,
    title: `Detecting Topology Attacks against Graph Neural Networks`,
    year: 2022,
    place: `The 1st Workshop on Trustworthy Learning on Graphs (TrustLOG) with CIKM 2022`,
    organization: `CIKM`,
    links: {},
    notes: `Best Paper Runner-up`,
  },
  {
    authors: [`Fei Xiong`, `Zulong Chen`, `Mingyuan Tao`, `Liangyue Li`, `Shoudi Hao`],
    authorIndex: 3,
    title: `Deep Position-wise Curve Network for Online Allocation in Sponsored Search`,
    year: 2022,
    place: `4th Workshop on Deep Learning Practice and Theory for High-Dimensional Sparse and Imbalanced Data with KDD 2022`,
    organization: `KDD`,
    links: {},
    notes: ``,
  },
  {
    authors: [`Qinghai Zhou`, `Liangyue Li`, `Nan Cao`, `Norbou Buchler`, `Hanghang Tong`],
    authorIndex: 1,
    title: `EXTRA: Explaining Team Recommendation in Networks`,
    year: 2018,
    place: `12th ACM Conference on Recommender Systems`,
    organization: `RecSys`,
    links: {},
    notes: `Demo Track`,
  },
  {
    authors: [
      `Liangyue Li`,
      `Hanghang Tong`,
      `Nan Cao`,
      `Kate Ehrlich`,
      `Yu-Ru Lin`,
      `Norbou Buchler`,
    ],
    authorIndex: 0,
    title: `TEAMOPT: Interactive Team Optimization in Big Networks`,
    year: 2016,
    place: `25th ACM International Conference on Information and Knowledge Management`,
    organization: `CIKM`,
    links: {
      PDF: `http://turingcat.me/pdfs/LiTCELB_CIKM16.pdf`,
      Demo: `http://team-net-work.org/system.html`,
    },
    notes: `Demo Track`,
  },
  {
    authors: [
      `Liangyue Li`,
      `Hanghang Tong`,
      `Nan Cao`,
      `Kate Ehrlich`,
      `Yu-Ru Lin`,
      `Norbou Buchler`,
    ],
    authorIndex: 0,
    title: `Enhancing Team Composition in Professional Networks: Problem Definitions and Fast Solutions`,
    year: 2016,
    place: `Poster at Science of Team Science Conference`,
    organization: `SciTS`,
    links: {},
    notes: ``,
  },
  {
    authors: [
      `Liangyue Li`,
      `Hanghang Tong`,
      `Nan Cao`,
      `Kate Ehrlich`,
      `Yu-Ru Lin`,
      `Norbou Buchler`,
    ],
    authorIndex: 0,
    title: `Computational Methods to Enhance Team Composition in Networks`,
    year: 2016,
    place: `Poster at the 2nd International Computational Social Science`,
    organization: `IC2S2`,
    links: {},
    notes: ``,
  },
];
