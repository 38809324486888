export default function Homepage() {
  return (
    <div className="py-2">
      <main>
        <div className="max-w-7xl mx-auto mt-10 py-6 sm:px-6 lg:px-8 flex flex-col md:flex-row sm:flex-col items-center text-justify">
          <div className="flex flex-col justify-center items-center mx-6">
            <div className="w-44 h-44">
              <img src="profile.jpeg" alt="me" className="rounded-full shadow-lg" />
            </div>
            <div className="flex flex-row justify-center items-center space-x-1 mt-2">
              <a title="Mail to me" href="mailto:liangyue@asu.edu" className="text-blue-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 w-9"
                  fill="none"
                  viewBox="2 -0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.8}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </a>
              <a title="Connect with me on LinkedIn" href="https://www.linkedin.com/in/liangyueli">
                <img src="LI-In-Bug.png" alt="LinkedIn" className="h-6 w-7" />
              </a>
              <a
                title="My Google Scholar Page"
                href="https://scholar.google.com/citations?user=xkx5nVQAAAAJ&hl=en"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 512 512">
                  <path fill="#4285f4" d="M256 411.12L0 202.667 256 0z" />
                  <path fill="#356ac3" d="M256 411.12l256-208.453L256 0z" />
                  <circle fill="#a0c3ff" cx="256" cy="362.667" r="149.333" />
                  <path
                    fill="#76a7fa"
                    d="M121.037 298.667c23.968-50.453 75.392-85.334 134.963-85.334s110.995 34.881 134.963 85.334H121.037z"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="px-4 py-6 sm:px-0 text-gray-600 font-medium">
            <p className="pb-4">
              I am now a machine learning engineer at Alibaba Group. Before that, I was an applied
              scientist with Amazon search. I received my Ph.D in Computer Science under the
              supervision of{" "}
              <a
                className="text-blue-700 hover:bg-blue-50 transition-all duration-200"
                href="http://tonghanghang.org/"
              >
                Prof. Hanghang Tong
              </a>{" "}
              from Arizona State University and my B.Eng from Tongji University in China.
            </p>
            <p className="pb-4">
              My research interests are in large language models, large scale data mining, and machine learning, with
              application to search, ads, recommender systems, and social network analysis.
            </p>
            <p className="pb-4">
              Check out our recent book:
              <br />
              <a
                className="text-blue-700 hover:bg-blue-50 transition-all duration-200"
                href="https://www.cambridge.org/us/academic/subjects/computer-science/knowledge-management-databases-and-data-mining/computational-approaches-network-science-teams?format=HB"
              >
                Computational Approaches to the Network Science of Teams, Cambridge University
                Press, 2021
              </a>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
