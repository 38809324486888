export default function Experiences() {
  return (
    <div className="py-2">
      <main>
        <div className="max-w-7xl mx-auto pt-6 pb-2 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            <span className="mr-3">Experiences</span>
          </h1>
        </div>
        <div className="px-4 max-w-7xl mx-auto py-0 sm:px-6 lg:px-8">
          <h2 className="font-semibold text-lg">Industry</h2>
          <div className="px-4 py-2 sm:px-0 text-gray-600 font-medium">
            <ul className="list-disc px-6">
              <li>
                Senior Algorithm Engineer, <span className="font-bold">Alibaba</span>, Hangzhou,
                China, 12.2020 - Present
              </li>
              <li>
                Applied Scientist, <span className="font-bold">Amazon</span>, Palo Alto, CA, USA,
                01.2019 - 04.2020
              </li>
              <li>
                Research Intern, <span className="font-bold">LinkedIn Corporation</span>, Mountain
                View, CA, USA, 05.2016 - 08.2016
              </li>
              <li>
                Research Intern, <span className="font-bold">DataXu Inc</span>, Boston, MA, USA,
                05.2013 - 08.2013
              </li>
            </ul>
          </div>

          <h2 className="font-semibold mt-4 text-lg sm:px-0">Education</h2>
          <div className="px-4 py-2 sm:px-0 text-gray-600 font-medium">
            <ul className="list-disc px-6">
              <li>
                Ph.D., Computer Science, <span className="font-bold">Arizona State University</span>
                , Tempe, AZ, USA, 2014 - 2018
              </li>
              <li>
                B. Eng, Computer Science, <span className="font-bold">Tongji University</span>,
                Shanghai, China, 2007-2011
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}
