// only links are optional
export const books = [
  {
    authors: [`Liangyue Li`, `Hanghang Tong`],
    authorIndex: 0,
    title: `Computational Approaches to the Network Science of Teams`,
    year: 2020,
    press: `Cambridge University Press`,
    links: {},
    identifier: {
      name: `ISBN`,
      id: `9781108498548`,
    },
  },
];
