export default function Default() {
  return (
    <div>
      <main>
        <div className="flex items-center justify-center h-52 font-bold text-3xl">
          Page Not Found :(
        </div>
      </main>
    </div>
  );
}
