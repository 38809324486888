export const services = [
  {
    position: `Senior Program Committee`,
    place: `33rd ACM International Conference on Information and Knowledge Management`,
    organization: `CIKM`,
    year: 2024,
    link: ``,
  },
  {
    position: `Senior Program Committee`,
    place: `38th AAAI Conference on Artificial Intelligence`,
    organization: `AAAI`,
    year: 2024,
    link: ``,
  },
  {
    position: `Senior Program Committee`,
    place: `32nd ACM International Conference on Information and Knowledge Management`,
    organization: `CIKM`,
    year: 2023,
    link: ``,
  },
  {
    position: `Senior Program Committee`,
    place: `37th AAAI Conference on Artificial Intelligence`,
    organization: `AAAI`,
    year: 2023,
    link: ``,
  },
  {
    position: `Senior Program Committee`,
    place: `31st ACM International Conference on Information and Knowledge Management`,
    organization: `CIKM`,
    year: 2022,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    year: 2022,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The Web Conference`,
    organization: `WWW`,
    year: 2022,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `36th AAAI Conference on Artificial Intelligence`,
    organization: `AAAI`,
    year: 2022,
    link: ``,
  },
  {
    position: `Senior Program Committee`,
    place: `30th ACM International Conference on Information and Knowledge Management`,
    organization: `CIKM`,
    year: 2021,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `44th International ACM SIGIR Conference on Research and Development in Information Retrieval`,
    organization: `SIGIR`,
    year: 2021,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    year: 2021,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The Web Conference`,
    organization: `WWW`,
    year: 2020,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `8th International Conference on Learning Representations`,
    organization: `ICLR`,
    year: 2020,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `34th AAAI Conference on Artificial Intelligence`,
    organization: `AAAI`,
    year: 2020,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `Neural Information Processing Systems`,
    organization: `NeurIPS`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `28th International Joint Conference on Artificial Intelligence`,
    organization: `IJCAI`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `THE 25TH ACM SIGKDD Conference on Knowledge Discovery and Data Mining `,
    organization: `KDD`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `36th International Conference on Machine Learning`,
    organization: `ICML`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The Web Conference `,
    organization: `WWW`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The Web Conference `,
    organization: `WWW`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `7th International Conference on Learning Representations`,
    organization: `ICLR`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `SIAM International Conference on Data Mining`,
    organization: `SDM`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `33rd AAAI Conference on Artificial Intelligence`,
    organization: `AAAI`,
    year: 2019,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `IEEE International Conference on Data mining`,
    organization: `ICDM`,
    year: 2018,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The Thirty-second Annual Conference on Neural Information Processing Systems`,
    organization: `NIPS`,
    year: 2018,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `24th ACM SIGKDD Conference on Knowledge Discovery and Data Mining`,
    organization: `KDD`,
    year: 2018,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: ` The 2018 IEEE/ACM International Conference on Advances in Social Networks Analysis and Mining`,
    organization: `ASONAM`,
    year: 2018,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `SIAM International Conference on Data Mining`,
    organization: `SDM`,
    year: 2018,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The 32nd Conference on Artificial Intelligence`,
    organization: `AAAI`,
    year: 2018,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: ` The 7th CCF Conference on Natural Language Processing and Chinese Computing`,
    organization: `NLPCC`,
    year: 2018,
    link: ``,
  },
  {
    position: `Technical Committee`,
    place: `International Conference on Pattern Recognition`,
    organization: `ICPR`,
    year: 2018,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The 26th ACM International Conference on Information and Knowledge Management`,
    organization: `CIKM`,
    year: 2017,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: ` The 2017 IEEE ICDM Data Science for Human Capital Management (DSHCM) workshop`,
    organization: `DSHCM`,
    year: 2017,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: ` The 2017 IEEE/ACM International Conference on Advances in Social Networks Analysis and Mining`,
    organization: `ASONAM`,
    year: 2017,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The Thirty-First AAAI Conference on Artificial Intelligence`,
    organization: `AAAI`,
    year: 2017,
    link: ``,
  },
  {
    position: `Technical Committee`,
    place: `International Conference on Pattern Recognition`,
    organization: `ICPR`,
    year: 2016,
    link: ``,
  },
  {
    position: `Organizer`,
    place: `CIKM 2016 Workshop on Big Network Analytics`,
    organization: `BigNet`,
    year: 2016,
    link: ``,
  },
  {
    position: `Program Committee`,
    place: `The 2016 IEEE/ACM International Conference on Advances in Social Networks Analysis and Mining `,
    organization: `ASONAM`,
    year: 2016,
    link: ``,
  },
  {
    position: `Web and Publicity Chair`,
    place: `KDD 2015 workshop on Connected Health at Big Data Era`,
    organization: `BigChat`,
    year: 2015,
    link: ``,
  },
  {
    position: `Technical Committee`,
    place: `International Conference on Pattern Recognition`,
    organization: `ICPR`,
    year: 2014,
    link: ``,
  },
];
